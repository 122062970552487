import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// CSS
import './ContactForm.scss'

// IMAGE
import arrow from '../../img/arrow-menu.svg'

const ContactForm = () => {
  const {
    site: {
      siteMetadata: { siteUrl: baseUrl },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl: url
        }
      }
    }
  `)

  const defaultFields = {
    name: '',
    email_address: '',
    phone_number: '',
    message: '',
  }

  const [fields, setFields] = useState(defaultFields)

  const [success, setSuccess] = useState(false)

  const onChange = e => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = e => {
    e.preventDefault()

    const formData = new FormData()

    formData.append('input_1', fields.name)
    formData.append('input_2', fields.email_address)
    formData.append('input_3', fields.message)

    try {
      const request = fetch(`https://admin.stedelijkekavelruil.nl/wp-json/gf/v2/forms/1/submissions`, {
        method: 'POST',
        body: formData,
      })

      request
        .then(response => response.json())
        .then(() => {
          setFields(defaultFields)
          setSuccess(true)
        })
    } catch (error) {
      setSuccess(false)
      throw error
    }
  }

  return (
    <form onSubmit={onSubmit} className="form contact">
      {success && <div>Bericht succesvol verzonden</div>}
      <div className="form-group ">
        <label for="name">Naam</label>
        <input
          type="text"
          className="form-control"
          required
          name="name"
          value={fields.name}
          onChange={onChange}
        />
        <label for="email_address">E-mailadres</label>
        <input
          type="text"
          className="form-control"
          required
          name="email_address"
          value={fields.email_address}
          onChange={onChange}
        />
      </div>
      <div className="form-group ">
        <label for="message">Vraag</label>
        <textarea
          className="form-control"
          required
          name="message"
          value={fields.message}
          onChange={onChange}
        />
      </div>
      <div className="form-group check">
        <label htmlFor="approved">
          <input
            type="checkbox"
            id="approved"
            required
            className="form-control"
            name="approved"
            onChange={onChange}
          />
          <span>
            Ja, ik ga akkoord met de
            {' '}
            <Link to="/privacyverklaring/">privacyverklaring</Link>
            {' '}
            en
            {' '}
            <Link to="/algemene-voorwaarden/">algemene voorwaarden</Link>
            &nbsp;van
            stedelijkekavelruil.nl
          </span>
        </label>
        <button type="submit" className="btn">
          Verstuur
          <img src={arrow} alt="arrow" width="18" className="button-img" />
        </button>
      </div>
    </form>
  )
}

export default ContactForm
